import settingsParams from '../components/BookingCalendar/settingsParams';
import { LayoutOptions } from '../types/types';

export const isDailyTimeSlotsMonthlyPickerLayout = (settings: any): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER;

export const isDailyTimeSlotsWeeklyPickerLayout = (settings: any): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_TIME_SLOTS_WEEKLY_PICKER;

export const isDailyAgendaWeeklyPickerLayout = (settings: any): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER;

export const isWeeklyTimeSlotsLayout = (settings: any): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.WEEKLY_TIME_SLOTS;

export const isWeeklyTimeTableLayout = (settings: any): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.WEEKLY_TIMETABLE;
