import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { SetSelectedRange } from '../setSelectedRange/setSelectedRange';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { AddError } from '../addError/addError';
import { getLocalDateTimeRangeForDay } from '../../../../utils/getLocalDateTimeRangeForDay/getLocalDateTimeRangeForDay';
import {
  bookingsCalendarErrorMessages,
  bookingsCalendarClick,
} from '@wix/bi-logger-wixboost-ugc/v2';
import {
  getEndOfMonthAsLocalDateTime,
  isMonthDifferent,
} from '../../../../utils/dateAndTime/dateAndTime';
import {
  CalendarErrors,
  SlotsStatus,
  TriggeredByOptions,
} from '../../../../types/types';
import { isWeeklyTimeSlotsLayout } from '../../../../utils/layouts';

export type GoToNextAvailableDate = () => void;

export function createGoToNextAvailableDateAction(
  {
    getControllerState,
    context: { biLogger, calendarApi, settings, businessInfo, experiments },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  setSelectedRange: SetSelectedRange,
  addError: AddError,
): GoToNextAvailableDate {
  return async () => {
    const [state, setState] = getControllerState();
    const { selectedDate } = state;

    setState({
      slotsStatus: SlotsStatus.LOADING,
    });

    const isChangeNextAvailableRangeEnabled = experiments.enabled(
      'specs.bookings.changeNextAvailableRange',
    );
    let nextAvailableLocalDateTime;
    if (isChangeNextAvailableRangeEnabled) {
      const sixMonthsFromSelectedDate = getEndOfMonthAsLocalDateTime(
        selectedDate!,
        7,
      );

      nextAvailableLocalDateTime = await calendarApi.getNextAvailableDate(
        {
          fromAsLocalDateTime: selectedDate!,
          toAsLocalDateTime: sixMonthsFromSelectedDate,
        },
        { state, settings, onError: addError },
      );
    } else {
      const threeMonthsFromSelectedDate = getEndOfMonthAsLocalDateTime(
        selectedDate!,
        4,
      );

      nextAvailableLocalDateTime = await calendarApi.getNextAvailableDate(
        {
          fromAsLocalDateTime: selectedDate!,
          toAsLocalDateTime: threeMonthsFromSelectedDate,
        },
        { state, settings, onError: addError },
      );
    }

    if (nextAvailableLocalDateTime) {
      if (isWeeklyTimeSlotsLayout(settings)) {
        const range = getLocalDateTimeRangeForDay(
          businessInfo!.dateRegionalSettingsLocale!,
          nextAvailableLocalDateTime,
        );
        await setSelectedRange(
          range,
          TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
        );
      } else {
        const shouldFetchDateAvailability = isMonthDifferent(
          selectedDate!,
          nextAvailableLocalDateTime,
        );

        await Promise.all([
          setSelectedDate(
            nextAvailableLocalDateTime,
            TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
          ),
          shouldFetchDateAvailability
            ? setSelectedMonth(
                nextAvailableLocalDateTime,
                TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
              )
            : Promise.resolve(),
        ]);
      }

      void biLogger.report(
        bookingsCalendarClick({
          component: WidgetComponents.TIME_PICKER,
          element: WidgetElements.GO_TO_NEXT_AVAILABLE_DATE_LINK,
          properties: JSON.stringify({
            selectedDate,
            nextAvailableDate: nextAvailableLocalDateTime,
          }),
        }),
      );
    } else {
      setState({
        slotsStatus: SlotsStatus.NO_AVAILABLE_SLOTS,
      });
      void biLogger.report(
        bookingsCalendarErrorMessages({
          errorMessage: CalendarErrors.NO_NEXT_AVAILABLE_DATE_WARNING,
        }),
      );
    }
  };
}
